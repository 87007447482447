body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.App {
  padding: 0;
  position: relative;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  max-width: 400px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.buttonsHorizontal1 {
  position: absolute;
  top: 15px;
  left: 15px;
}

.buttonsHorizontal2 {
  position: absolute;
  top: 80px;
  left: 15px;
}

.buttonsHorizontal3 {
  position: absolute;
  top: 140px;
  left: 15px;
}

h1 {
  position: absolute;
  top: 14px;
  right: 15px;
  width: 100px;
  text-align: right;
  color: orangered;
  font-size: 18px;
}

.Sum {
  padding-top: 225px;
  margin-left: 90px;
  font-size: 45px;
  font-weight: bold;
}


.choices {
  margin-top: 10px;
  margin-left: 85px;
}

.choices .Button {
  border: 1px solid darkgrey;
}

.Score {
  margin-top: 10px;
  margin-left: 90px;
}

.Squirrel {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 200px;
}

iframe {
  display: block;
  width: 90%;
  height: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 85px
}

.version {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
