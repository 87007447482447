.Button {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 5px;
  line-height: 50px;
  text-align: center;
  border: 1px solid lightgray;
  font-size: 18px;
  font-weight: bold;
}

.RefreshButton {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: -17px;
  width: 50px;
  height: 50px;
  background-image: url(../img/refresh.png);
  background-size: contain;
}
